<template>
  <q-dialog
    ref="dialogRef"
    :maximized="$q.screen.lt.sm"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="onDialogHide"
  >
    <base-dialog-content content-classes="q-pa-none">
      <template v-if="navigationStack?.length !== 0" #header>
        <div class="flex no-wrap items-center">
          <q-btn
            v-if="navigationStack?.length !== 0"
            :icon="'fas fa-arrow-left'"
            round
            color="white"
            text-color="dark"
            aria-label="close"
            style="margin-left: -8px"
            unelevated
            @click="goBack"
          />

          <div>
            <h4>{{ currentCategory?.name }}</h4>
          </div>
        </div>
      </template>

      <template #default>
        <template v-if="loading">
          <div class="relative-position full-height" style="min-height: 250px">
            <div class="absolute-center">
              <q-spinner size="xl"></q-spinner>
            </div>
          </div>
        </template>
        <template v-else>
          <q-list v-if="categories" separator>
            <q-item
              v-for="category in navigationStack.length ? currentCategory.children : categories"
              :key="category.id"
              v-ripple
              clickable
              class="category-item"
              :class="{'selected': category?.id && selectedCategory?.id === category.id}"
              @click="toggleCategory(category)"
            >
              <q-item-section>
                <q-item-label>
                  <div class="flex no-wrap items-center" style="column-gap: 12px">
                    <div>
                      {{ category.name }}
                    </div>
                    <div v-if="category.children">
                      <q-icon name="fas fa-caret-right"/>
                    </div>
                    <div v-if="category?.id && selectedCategory?.id === category.id">
                      <q-icon size="sm" name="fas fa-square-check"/>
                    </div>
                  </div>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </template>
      </template>

      <template #footer>
        <div class="flex no-wrap justify-end" style="column-gap: 12px">
          <q-btn v-close-popup unelevated no-caps>
            Abbrechen
          </q-btn>
          <q-btn :disable="leafOnly && !selectedCategory?.id" unelevated no-caps color="dark" @click="onOKClick">
            <template v-if="!selectedCategory?.id && currentCategory?.id && navigationStack?.length > 0">
              "{{ currentCategory.name }}"
            </template>
            Auswählen
          </q-btn>
        </div>
      </template>
    </base-dialog-content>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';
import {onMounted, ref} from 'vue';
import {useMarketStore} from 'src/apps/market/store';

const categories = ref()
const currentCategory = ref()
const selectedCategory = ref()
const navigationStack = ref([])
const loading = ref(true)
const marketStore = useMarketStore()

const props = defineProps({
  leafOnly: {type: Boolean, default: false},
  showOnlyAvailable: {type: Boolean, default: false},
})

const toggleCategory = (category) => {
  if (category?.id === selectedCategory.value?.id) {
    selectedCategory.value = currentCategory
    return
  }

  selectedCategory.value = undefined
  if (category.children && category.children.length) {
    navigationStack.value.push(category)
    currentCategory.value = category
  } else {
    selectedCategory.value = category
  }
}

const goBack = () => {
  selectedCategory.value = undefined
  if (navigationStack.value.length) {
    currentCategory.value = navigationStack.value.pop()
  } else {
    currentCategory.value = undefined
  }
}

function fetchCategories() {
  let fetchFn = marketStore.fetchCategories()
  if (props.showOnlyAvailable) {
    fetchFn = marketStore.fetchAvailableCategories()
  }

  fetchFn.then((data) => {
    categories.value = data
    loading.value = false
  })
}

defineEmits([
  ...useDialogPluginComponent.emits
])

onMounted(() => {
  fetchCategories()
})

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()

function onOKClick() {
  const payload = [
    ...navigationStack.value,

  ]
  if (selectedCategory.value) {
    payload.push(selectedCategory.value)
  } else {
    if (props.leafOnly) {
      throw new Error('Bitte wähle eine Kategorie aus')
    }
  }

  onDialogOK(payload)
}

</script>

<style scoped lang="scss">
.category-item {
  &.selected {
    background-color: $primary;
    color: white;
  }
}
</style>
