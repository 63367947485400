import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "absolute text-white full-width ellipsis",
  style: {"z-index":"2","bottom":"3px","transform":"translateX(-50%)","left":"50%"}
}
const _hoisted_2 = { class: "text-center text-caption" }
const _hoisted_3 = {
  class: "flex no-wrap items-center justify-between",
  style: {}
}
const _hoisted_4 = { class: "flex column justify-center items-center" }
const _hoisted_5 = { class: "relative-position flex items-center justify-center" }
const _hoisted_6 = { class: "c-icon" }

import {searchTypeMapping} from 'src/apps/search/helpers'
import {computed, nextTick, ref, watch} from 'vue'
import {DEFAULT_SEARCH_TYPE} from 'src/apps/search/vars'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchTypeSwitch',
  props: {
  modelValue: { required: true, default: DEFAULT_SEARCH_TYPE },
},
  emits: ['update:modelValue', 'click'],
  setup(__props, { emit: __emit }) {

const $emit = __emit
const props = __props

const choices: Array<any> = []
const wrapperRef = ref<HTMLElement | null>(null)
const contentRef = ref({})
const btnBgRef = ref<HTMLElement | null>(null)
const initBtnBg = ref(false)

Object.keys(searchTypeMapping).map((key) => {
  choices.push({ val: key, ...searchTypeMapping[key] })
})

const currentChoice = computed(() => {
  return choices.find((obj) => obj.val === props.modelValue)
})

function positionBg(choice) {
  if (wrapperRef.value && btnBgRef.value && choice) {
    const wrapperRect = wrapperRef.value.getBoundingClientRect()
    const contentRect = contentRef.value[choice].$el.getBoundingClientRect()

    btnBgRef.value.style.minWidth = `${contentRect.width}px`
    btnBgRef.value.style.height = `${contentRect.height}px`
    btnBgRef.value.style.left = `${contentRect.left - wrapperRect.left}px`
    btnBgRef.value.style.top = `${contentRect.top - wrapperRect.top}px`
    if (!initBtnBg.value) {
      initBtnBg.value = true
    }
  }
}

watch(
  () => props.modelValue,
  (val) => {
    nextTick(() => {
      positionBg(val)
    })
  },
  { immediate: true }
)

const handleClick = (choice) => {
  wrapperRef.value?.classList.add('c-is-clicked')
  $emit('update:modelValue', choice.val)
  $emit('click', choice)
}

return (_ctx: any,_cache: any) => {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_resize_observer = _resolveComponent("q-resize-observer")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref_key: "wrapperRef",
      ref: wrapperRef,
      class: "c-wrapper bg-white relative-position"
    }, [
      (_ctx.$q.platform.is.ios)
        ? (_openBlock(), _createBlock(_component_q_separator, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_q_resize_observer, {
        onResize: _cache[0] || (_cache[0] = ($event: any) => (positionBg(currentChoice.value.val)))
      }),
      _withDirectives(_createElementVNode("div", {
        ref_key: "btnBgRef",
        ref: btnBgRef,
        class: "bg-tertiary absolute c-bg-btn",
        style: {"z-index":"0"}
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(currentChoice.value.label), 1)
        ])
      ], 512), [
        [_vShow, __props.modelValue && initBtnBg.value]
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(choices, (choice, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: choice.val
          }, [
            _createVNode(_component_q_btn, {
              ref_for: true,
              ref: (el) => (contentRef.value[choice.val] = el),
              "no-caps": "",
              class: _normalizeClass(["q-px-md relative-position c-btn", { 'c-btn__active': choice.val === __props.modelValue && initBtnBg.value }]),
              clickable: "",
              square: "",
              unelevated: "",
              size: "1rem",
              ripple: true,
              style: {"flex-grow":"1","padding-top":"20px","padding-bottom":"20px"},
              onClick: ($event: any) => (handleClick(choice))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_q_icon, {
                        name: choice.icon
                      }, null, 8, ["name"])
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["class", "onClick"]),
            (index + 1 !== choices.length)
              ? (_openBlock(), _createBlock(_component_q_separator, {
                  key: 0,
                  vertical: ""
                }))
              : _createCommentVNode("", true)
          ], 64))
        }), 64))
      ])
    ], 512)
  ]))
}
}

})