
import {computed, defineComponent, onBeforeUnmount, onMounted, Ref, ref, watch} from 'vue';
import ProfileName from 'src/components/etc/ProfileName.vue';
import {useMainStore} from 'stores/main';
import Messages from 'src/api/messages';
import {debounce, Notify} from 'quasar';
import {onBeforeRouteUpdate, useRouter} from 'vue-router';
import {MessageModel} from 'src/models/message.model';
import SrcsetImg from 'components/qutils/SrcsetImg.vue';


export default defineComponent({
  components: {SrcsetImg, ProfileName},

  setup() {
    const mainStore = useMainStore()
    const chatContainer = ref<Ref | null>(null)
    const autoScrollActive = ref(true)
    const currentMessage = ref<string | undefined>()
    const isSendingMessage = ref(false)
    const loadingMessages = ref(false)
    const messageInput = ref<Ref | null>(null)
    const router = useRouter()

    const userUuid = computed(() => {
      return mainStore.accountDetail?.uuid
    })

    const isReadOnlyInput = computed(() => {
      return !!(isSendingMessage.value ||
        mainStore.userActiveChat?.advert?.deleted ||
        (mainStore.userActiveChat?.participants?.length && mainStore.userActiveChat?.participants?.length < 2))
    })

    const messages = computed((): Array<MessageModel> => {
      return mainStore.userActiveChatMessages
    })

    const scrollToBottom = () => {
      if (!chatContainer.value) return
      if (!autoScrollActive.value) return

      let el = chatContainer.value.$el
      setTimeout(() => {
        el.scrollTop = el.scrollHeight
      }, 10)
    }

    watch(
      () => mainStore.userActiveChatMessages,
      () => {
        scrollToBottom()
      },
      {immediate: true}
    )

    onBeforeRouteUpdate((to, from, next) => {
      autoScrollActive.value = true
      next()
    })


    const fetchMessages = (showLoading = true) => {
      if (!mainStore.userActiveChat?.uuid) return
      loadingMessages.value = showLoading
      mainStore.fetchChat(mainStore.userActiveChat.uuid)
      mainStore.fetchActiveChatMessages(mainStore.userActiveChat.uuid).catch(err => {
        console.error(err)
        return err
      }).finally(() => {
        loadingMessages.value = false
        scrollToBottom()
      })
    }

    const onChatScroll = debounce((e) => {
      let maxScrollTop = e.target.scrollHeight - e.target.offsetHeight
      autoScrollActive.value = e.target.scrollTop === maxScrollTop
    }, 50)

    const markRead = (chatUuid) => {
      if (!chatUuid) return
      Messages.markReadMessage(chatUuid).catch(err => {
        console.error(err)
      })
      mainStore.fetchUnreadMessagesCount()
    }

    let timeout, messagePolling
    onMounted(() => {
      scrollToBottom()

      const btn = document.getElementById('btn-submit')
      if (btn) {
        btn.addEventListener('touchend', (e) => {
          e.preventDefault()
          createMessage()
          messageInput.value.focus()
        })
      }

      if (mainStore.userActiveChat) {
        try {
          timeout = Number.parseInt(process.env.MESSAGES_POLLING || '')
        } catch (e) {
          console.error(e)
        }
        if (timeout) {
          clearInterval(messagePolling)
          messagePolling = setInterval(() => {
            // mark current chat as read
            if (mainStore.userChatLoading) return
            fetchMessages(false)
            markRead(mainStore.userActiveChat?.uuid)
          }, timeout)
        }
      }
    })

    onBeforeUnmount(() => {
      if (messagePolling) {
        clearInterval(messagePolling)
      }
    })

    const createMessage = () => {
      messageInput.value.focus({focusVisible: true})
      if (!mainStore.userActiveChat) return
      if (!currentMessage.value || currentMessage.value === '') return

      let _msg = currentMessage.value

      currentMessage.value = ''
      isSendingMessage.value = true
      Messages.createMessage(mainStore.userActiveChat.uuid, {
        content: _msg,
      })
        .then(() => {
          mainStore.fetchMessengerUserChats()
          fetchMessages(false)
        }).catch(err => {
        console.error(err)
        currentMessage.value = _msg
        return err
      }).finally(() => {
        isSendingMessage.value = false
        messageInput.value.focus({focusVisible: true})
      })
    }

    const inputOnKeyDown = function (e) {
      if (isSendingMessage.value) return
      if (['NumpadEnter', 'Enter'].includes(e.code) && e.ctrlKey) {
        createMessage()
        return
      }
    }

    const getChatReceiver = function (participants) {
      if (!mainStore.accountDetail?.uuid) return participants
      return participants.filter(obj => !obj.is_current_account)
    }

    const deactivateChat = function (chatUuid) {
      Messages.deactivateChat(chatUuid).then(() => {
        mainStore.fetchMessengerUserChats()
        router.replace({name: 'messenger'})
        mainStore.setActiveChat(undefined)
        Notify.create({
          message: 'Chat wurde gelöscht.'
        })
      })
    }


    return {
      getChatReceiver,
      userUuid,
      messages,
      fetchMessages,
      isSendingMessage,
      currentMessage,
      onChatScroll,
      createMessage,
      scrollToBottom,
      messageInput,
      chatContainer,
      inputOnKeyDown,
      loadingMessages,
      mainStore,
      isReadOnlyInput,
      deactivateChat,
    }
  },

  async preFetch({store, currentRoute, redirect, urlPath}) {
    const mainStore = useMainStore(store)
    mainStore.userActiveChat = undefined
    let chatUuid = currentRoute.params.chatUuid?.toString()
    if (!chatUuid) {
      if (!mainStore.userChats?.length) return
      // use last chat as active
      chatUuid = mainStore.userChats[0]['uuid']
    }

    mainStore.userActiveChatMessages = []
    return mainStore.fetchChat(chatUuid).then(async () => {
      await mainStore.setActiveChat(mainStore.userChatDetails[chatUuid])
      await mainStore.fetchActiveChatMessages(chatUuid, true)
      await mainStore.fetchUnreadMessagesCount()
    }).catch(err => {
      const {response} = err
      if ([404].includes(response?.status)) {
        redirect({name: 'messenger'})
      } else if ([400].includes(response?.status)) {
        redirect({name: '404', query: {url: urlPath}})
      } else {
        console.error(err)
      }
      return err
    })
  }
})
