import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"height":"calc(320px + 1rem)"} }
const _hoisted_3 = { class: "relative-position" }
const _hoisted_4 = { class: "flex column q-gutter-sm items-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "font-fjalla-one text-grey-8" }
const _hoisted_7 = { class: "text-small" }
const _hoisted_8 = { class: "text-muted text-small" }
const _hoisted_9 = {
  class: "text-italic",
  style: {"overflow-wrap":"break-word"}
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "text-italic text-muted"
}
const _hoisted_12 = { class: "absolute-top-left q-pa-md" }
const _hoisted_13 = { class: "absolute-bottom-right q-pa-md" }
const _hoisted_14 = { style: {"z-index":"2"} }
const _hoisted_15 = {
  class: "text-small text-italic",
  style: {"overflow-wrap":"break-word"}
}

import cms from 'src/api/cms';
import {ref} from 'vue';
import {TestimonialModel} from 'src/models/cms'
import FlipCard from 'src/components/utils/FlipCard.vue';
import {truncate} from 'src/etc/utils';
import ResponsiveItemSlider from 'components/qutils/ResponsiveItemSlider.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TestimonialWidget',
  setup(__props) {

const testimonials = ref<Array<TestimonialModel>>()

cms.getTestimonials().then(resp => {
  testimonials.value = resp.data
}).catch(err => {
  console.error(err)
})

const flip = ref({})


return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_rating = _resolveComponent("q-rating")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (testimonials.value?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "container text-h2 text-white font-inter-bold q-mb-md" }, "Was unsere Community denkt", -1)),
        _createVNode(ResponsiveItemSlider, {
          "infinite-scroll": false,
          items: testimonials.value,
          "wrapper-class": "q-col-gutter-md",
          "skeleton-amount": _ctx.$q.screen.lt.lg ? 6 : 8,
          "col-classes": "col-6 col-md-4 col-lg-3",
          "item-max-width": "290px"
        }, {
          default: _withCtx(({item, index}) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(FlipCard, {
                modelValue: flip.value[index],
                "onUpdate:modelValue": ($event: any) => ((flip.value[index]) = $event)
              }, {
                front: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_card, {
                        class: _normalizeClass([{'flip': flip.value[index]}, "bg-grey-1"]),
                        bordered: "",
                        flat: "",
                        style: {"height":"320px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_card_section, {
                            class: "text-center q-pb-none",
                            onClick: _withModifiers(($event: any) => (item.text?.length > 130 ? flip.value[index] = !flip.value[index] : undefined), ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                (item.advert_image || item.user_avatar)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      (item.advert_image)
                                        ? (_openBlock(), _createBlock(_component_q_avatar, {
                                            key: 0,
                                            style: _normalizeStyle({marginRight: item.user_avatar ? '-.65rem' : undefined}),
                                            size: "6rem",
                                            square: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_img, {
                                                src: item.advert_image?.path
                                              }, {
                                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                                  _createElementVNode("div", { class: "absolute-full text-uppercase font-fjalla-one text-small" }, [
                                                    _createElementVNode("div", { class: "full-height column justify-center" }, [
                                                      _createElementVNode("div", { class: "" }, "Verkauft")
                                                    ])
                                                  ], -1)
                                                ])),
                                                _: 2
                                              }, 1032, ["src"])
                                            ]),
                                            _: 2
                                          }, 1032, ["style"]))
                                        : _createCommentVNode("", true),
                                      (item.user_avatar)
                                        ? (_openBlock(), _createBlock(_component_q_avatar, {
                                            key: 1,
                                            style: _normalizeStyle([{marginLeft: item.advert_image ? '-.65rem' : undefined}, {"border":"3px white solid","box-sizing":"content-box"}]),
                                            class: "shadow-3",
                                            size: "6rem"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_img, {
                                                "no-spinner": "",
                                                src: item.user_avatar?.path
                                              }, null, 8, ["src"])
                                            ]),
                                            _: 2
                                          }, 1032, ["style"]))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1),
                                  (item.rating)
                                    ? (_openBlock(), _createBlock(_component_q_rating, {
                                        key: 0,
                                        max: item.rating,
                                        "model-value": item.rating,
                                        color: "orange-6",
                                        icon: "fas fa-star",
                                        readonly: "",
                                        size: "xs"
                                      }, null, 8, ["max", "model-value"]))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_q_card_section, { class: "q-pt-xs" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, _toDisplayString(item.advert_description || ' '), 1),
                                _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(truncate)(item.text, 100)), 1),
                                (item.text?.length > 130)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _cache[1] || (_cache[1] = _createTextVNode("  ")),
                                      _createElementVNode("a", {
                                        class: "cursor-pointer",
                                        href: "#",
                                        onClick: _withModifiers(($event: any) => (flip.value[index] = !flip.value[index]), ["prevent"])
                                      }, "mehr lesen", 8, _hoisted_10)
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ]),
                              (item.review_type)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.review_type), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_q_icon, {
                        class: "fas fa-quote-left text-grey-6",
                        size: "md"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_q_icon, {
                        class: "fas fa-quote-right text-grey-6",
                        size: "md"
                      })
                    ])
                  ])
                ]),
                back: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_q_card, {
                      class: _normalizeClass([{'flip': flip.value[index]}, "bg-grey-1 overflow-auto"]),
                      bordered: "",
                      flat: "",
                      style: {"height":"320px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card_section, {
                          onClick: _withModifiers(($event: any) => (flip.value[index] = !flip.value[index]), ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_15, _toDisplayString(item.text), 1),
                            _cache[2] || (_cache[2] = _createElementVNode("a", { href: "#" }, " Zurück ", -1))
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ])
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["items", "skeleton-amount"])
      ]))
    : _createCommentVNode("", true)
}
}

})